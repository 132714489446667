<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-v-component
      heading="Vehicle Sales With No Service"
    />
    <template v-if="error">
      <v-banner
        single-line
        elevation="10"
      >
        <v-icon
          slot="icon"
          color="warning"
          size="36"
        >
          mdi-wifi-strength-alert-outline
        </v-icon>
        <div>
          There was a problem communicating to the server!
          <div class="code">
            <strong>
              {{ error }}
            </strong>
          </div>
        </div>
      </v-banner>
    </template>
    <v-row>
      <v-col cols="12">
        <template>
          <v-stepper v-model="wizard">
            <v-stepper-header>
              <v-stepper-step
                :complete="wizard > 1"
                step="1"
              >
                Select Date Range
              </v-stepper-step>

              <v-divider />
              <v-stepper-step
                :complete="wizard > 2"
                step="2"
              >
                Select Finance Details
              </v-stepper-step>

              <v-divider />
              <v-stepper-step
                :complete="wizard > 3"
                step="3"
              >
                Select Year, Make, Model, State
              </v-stepper-step>

              <v-divider />
              <v-stepper-step
                :complete="wizard > 4"
                step="4"
              >
                Select Columns
              </v-stepper-step>
              <v-divider />
              <v-stepper-step
                :complete="wizard > 5"
                step="5"
              >
                Select Campaign
              </v-stepper-step>
              <v-divider />
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-card
                  color="lighten-1"
                >
                  <v-row>
                    <v-col>
                      <v-switch
                        v-model="use_all_dates_switch"
                        class="pl-2"
                        inset
                        :label="`Search all sale records: ${use_all_dates_switch ? 'Yes' : 'No'}`"
                      />
                    </v-col>
                  </v-row>
                  <template v-if="!use_all_dates_switch">
                    <v-col>
                      <v-alert
                        icon="mdi-information"
                        border="top"
                        dense
                        color="blue"
                        elevation="6"
                        :text="true"
                      >
                        Select the date ranges you wish to search <strong>below</strong>. This would be the date range for when the vehicles were sold. Any vehicles sold during this date range, but have never been serviced will be returned in the results.
                      </v-alert>
                    </v-col>
                    <v-row>
                      <v-col
                        class="xs"
                        sm="6"
                        md="4"
                        lg="2"
                        xl="1"
                      >
                        <v-menu
                          v-model="start_date_menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="formattedStartDate"
                              label="Start Date"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-on="on"
                            />
                          </template>
                          <v-date-picker
                            v-model="start_date"
                            @input="start_date_menu = false"
                          />
                        </v-menu>
                      </v-col>
                      <v-col
                        class="xs"
                        sm="6"
                        md="4"
                        lg="2"
                        xl="1"
                      >
                        <v-menu
                          v-model="end_date_menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="formattedEndDate"
                              label="End Date"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-on="on"
                            />
                          </template>
                          <v-date-picker
                            v-model="end_date"
                            @input="end_date_menu = false"
                          />
                        </v-menu>
                      </v-col>
                    </v-row>
                  </template>
                </v-card>

                <v-btn
                  color="primary"
                  @click="wizard = 2"
                >
                  Continue
                </v-btn>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-card
                  color="lighten-1"
                >
                  <v-row>
                    <v-col>
                      <v-switch
                        v-model="filter_by_apr_switch"
                        class="pl-2"
                        inset
                        :label="formatFilterMessage('APR', filter_by_apr_switch, apr_filter_operator_value, apr_filter_value)"
                      />
                    </v-col>
                    <v-dialog
                      v-model="show_apr_dialog"
                      persistent
                      max-width="500"
                    >
                      <v-card class="pl-2 pr-2">
                        <v-card-title class="headline">
                          Filter by APR
                        </v-card-title>
                        <v-row>
                          <v-col>
                            <v-list shaped>
                              <v-subheader>SELECT FILTER TYPE</v-subheader>
                              <v-list-item-group
                                v-model="apr_filter_operator_value"
                                color="primary"
                              >
                                <v-list-item
                                  v-for="(item, i) in filter_operators"
                                  :key="i"
                                >
                                  <v-list-item-icon>
                                    <v-icon v-text="item.icon" />
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title v-text="item.text" />
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list>
                          </v-col>
                          <v-col class="d-flex align-center">
                            <v-text-field
                              v-model="apr_filter_value"
                              label="APR"
                              outlined
                            />
                          </v-col>
                        </v-row>
                        <v-card-actions>
                          <v-spacer />
                          <v-btn
                            color="green darken-1"
                            text
                            @click="show_apr_dialog = false"
                          >
                            Done
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-col>
                      <v-switch
                        v-model="filter_by_payment"
                        class="pl-2"
                        inset
                        :label="formatFilterMessage('Payment Amount', filter_by_payment, payment_filter_operator_value, payment_filter_value)"
                      />
                    </v-col>
                    <v-dialog
                      v-model="show_payment_dialog"
                      persistent
                      max-width="500"
                    >
                      <v-card class="pl-2 pr-2">
                        <v-card-title class="headline">
                          Filter by Monthly Payment
                        </v-card-title>
                        <v-row>
                          <v-col>
                            <v-list shaped>
                              <v-subheader>SELECT FILTER TYPE</v-subheader>
                              <v-list-item-group
                                v-model="payment_filter_operator_value"
                                color="primary"
                              >
                                <v-list-item
                                  v-for="(item, i) in filter_operators"
                                  :key="i"
                                >
                                  <v-list-item-icon>
                                    <v-icon v-text="item.icon" />
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title v-text="item.text" />
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list>
                          </v-col>
                          <v-col class="d-flex align-center">
                            <v-text-field
                              v-model="payment_filter_value"
                              label="Monthly Payment"
                              outlined
                            />
                          </v-col>
                        </v-row>
                        <v-card-actions>
                          <v-spacer />
                          <v-btn
                            color="green darken-1"
                            text
                            @click="show_payment_dialog = false"
                          >
                            Done
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-col>
                      <v-switch
                        v-model="filter_by_payments_remaining"
                        class="pl-2"
                        inset
                        :label="formatFilterMessage('Payments Remaining', filter_by_payments_remaining, payments_remaining_filter_operator_value, payments_remaining_filter_value)"
                      />
                    </v-col>
                    <v-dialog
                      v-model="show_payments_remaining_dialog"
                      persistent
                      max-width="500"
                    >
                      <v-card class="pl-2 pr-2">
                        <v-card-title class="headline">
                          Filter by Payments Remaining
                        </v-card-title>
                        <v-row>
                          <v-col>
                            <v-list shaped>
                              <v-subheader>SELECT FILTER TYPE</v-subheader>
                              <v-list-item-group
                                v-model="payments_remaining_filter_operator_value"
                                color="primary"
                              >
                                <v-list-item
                                  v-for="(item, i) in filter_operators"
                                  :key="i"
                                >
                                  <v-list-item-icon>
                                    <v-icon v-text="item.icon" />
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title v-text="item.text" />
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list>
                          </v-col>
                          <v-col class="d-flex align-center">
                            <v-text-field
                              v-model="payments_remaining_filter_value"
                              label="Payments Remaining"
                              outlined
                            />
                          </v-col>
                        </v-row>
                        <v-card-actions>
                          <v-spacer />
                          <v-btn
                            color="green darken-1"
                            text
                            @click="show_payments_remaining_dialog = false"
                          >
                            Done
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-col>
                      <v-switch
                        v-model="filter_by_months_since_sale"
                        class="pl-2"
                        inset
                        :label="formatFilterMessage('Months Since Sale', filter_by_months_since_sale, months_since_sale_filter_operator_value, months_since_sale_filter_value)"
                      />
                    </v-col>
                    <v-dialog
                      v-model="show_months_since_sale_dialog"
                      persistent
                      max-width="500"
                    >
                      <v-card class="pl-2 pr-2">
                        <v-card-title class="headline">
                          Filter by Months Since Sale
                        </v-card-title>
                        <v-row>
                          <v-col>
                            <v-list shaped>
                              <v-subheader>SELECT FILTER TYPE</v-subheader>
                              <v-list-item-group
                                v-model="months_since_sale_filter_operator_value"
                                color="primary"
                              >
                                <v-list-item
                                  v-for="(item, i) in filter_operators"
                                  :key="i"
                                >
                                  <v-list-item-icon>
                                    <v-icon v-text="item.icon" />
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title v-text="item.text" />
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list>
                          </v-col>
                          <v-col class="d-flex align-center">
                            <v-text-field
                              v-model="months_since_sale_filter_value"
                              label="Months Since Sale"
                              outlined
                            />
                          </v-col>
                        </v-row>
                        <v-card-actions>
                          <v-spacer />
                          <v-btn
                            color="green darken-1"
                            text
                            @click="show_months_since_sale_dialog = false"
                          >
                            Done
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-col>
                      <v-switch
                        v-model="filter_by_term_percent_complete"
                        class="pl-2"
                        inset
                        :label="formatFilterMessage('% Term Complete', filter_by_term_percent_complete, term_percent_filter_operator_value, term_percent_filter_value)"
                      />
                    </v-col>
                    <v-dialog
                      v-model="show_term_percent_dialog"
                      persistent
                      max-width="500"
                    >
                      <v-card class="pl-2 pr-2">
                        <v-card-title class="headline">
                          Filter by % Term Complete
                        </v-card-title>
                        <v-row>
                          <v-col>
                            <v-list shaped>
                              <v-subheader>SELECT FILTER TYPE</v-subheader>
                              <v-list-item-group
                                v-model="term_percent_filter_operator_value"
                                color="primary"
                              >
                                <v-list-item
                                  v-for="(item, i) in filter_operators"
                                  :key="i"
                                >
                                  <v-list-item-icon>
                                    <v-icon v-text="item.icon" />
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title v-text="item.text" />
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list>
                          </v-col>
                          <v-col class="d-flex align-center">
                            <v-text-field
                              v-model="term_percent_filter_value"
                              label="% Term Complete"
                              outlined
                            />
                          </v-col>
                        </v-row>
                        <v-card-actions>
                          <v-spacer />
                          <v-btn
                            color="green darken-1"
                            text
                            @click="show_term_percent_dialog = false"
                          >
                            Done
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-row>
                </v-card>
                <v-btn
                  color="primary"
                  @click="wizard = 3"
                >
                  Continue
                </v-btn>
                <v-btn
                  text
                  @click="wizard = 1"
                >
                  Back
                </v-btn>
              </v-stepper-content>

              <v-stepper-content step="3">
                <v-card
                  color="lighten-1"
                >
                  <v-row>
                    <v-col>
                      <v-switch
                        v-model="filter_by_condition_switch"
                        class="pl-2"
                        inset
                        :label="'Filter by New / Used? ' + formatBoolean (filter_by_condition_switch)"
                      />
                    </v-col>
                    <template v-if="filter_by_condition_switch">
                      <v-col>
                        <v-combobox
                          v-model="selected_conditions"
                          :items="all_conditions"
                          label="Select the conditions you want to filter by"
                          multiple
                        />
                      </v-col>
                    </template>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-switch
                        v-model="filter_by_years_switch"
                        class="pl-2"
                        inset
                        :label="'Filter by Year? ' + formatBoolean (filter_by_years_switch)"
                      />
                    </v-col>
                    <template v-if="filter_by_years_switch">
                      <v-col>
                        <v-combobox
                          v-model="selected_years"
                          :items="all_years"
                          label="Select the years you want to filter by"
                          multiple
                        />
                      </v-col>
                    </template>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-switch
                        v-model="filter_by_makes_switch"
                        class="pl-2"
                        inset
                        :label="'Filter by Make? ' + formatBoolean (filter_by_makes_switch)"
                      />
                    </v-col>
                    <template v-if="filter_by_makes_switch">
                      <v-col>
                        <v-combobox
                          v-model="selected_makes"
                          :items="all_makes"
                          label="Select the makes you want to filter by"
                          multiple
                        />
                      </v-col>
                    </template>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-switch
                        v-model="filter_by_models_switch"
                        class="pl-2"
                        inset
                        :label="'Filter by Model? ' + formatBoolean (filter_by_models_switch)"
                      />
                    </v-col>
                    <template v-if="filter_by_models_switch">
                      <v-col>
                        <v-combobox
                          v-model="selected_models"
                          :items="all_models"
                          label="Select the models you want to filter by"
                          multiple
                        />
                      </v-col>
                    </template>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-switch
                        v-model="filter_by_states_switch"
                        class="pl-2"
                        inset
                        :label="'Filter by State? ' + formatBoolean (filter_by_states_switch)"
                      />
                    </v-col>
                    <template v-if="filter_by_states_switch">
                      <v-col>
                        <v-combobox
                          v-model="selected_states"
                          :items="all_states"
                          label="Select the states you want to filter by"
                          multiple
                        />
                      </v-col>
                    </template>
                  </v-row>
                </v-card>
                <v-btn
                  color="primary"
                  @click="wizard = 4"
                >
                  Continue
                </v-btn>
                <v-btn
                  text
                  @click="wizard = 2"
                >
                  Back
                </v-btn>
              </v-stepper-content>
              <v-stepper-content step="4">
                <v-card
                  color="lighten-1"
                >
                  <v-row>
                    <v-col>
                      <v-combobox
                        v-model="selectedHeaders"
                        :items="allHeaders"
                        label="Select the columns you want to appear"
                        multiple
                      />
                    </v-col>
                  </v-row>
                </v-card>
                <v-btn
                  color="primary"
                  @click="wizard = 5"
                >
                  Next
                </v-btn>
                <v-btn
                  text
                  @click="wizard = 3"
                >
                  Back
                </v-btn>
              </v-stepper-content>
              <v-stepper-content step="5">
                <v-card
                  color="lighten-1"
                >
                  <v-row dense>
                    <v-col>
                     <v-select
                      :items="campaigns"
                      label="Campaigns"
                      outlined
                      item-text="name"
                      item-value="id"
                      v-model="campaignId"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-text-field
                        v-model="fileDescription"
                        label="File Description"
                      />
                    </v-col>
                  </v-row>                  
                </v-card>
                <v-btn
                  color="primary"
                  @click="loadSales"
                >
                  Finish
                </v-btn>
                <v-btn
                  text
                  @click="wizard = 3"
                >
                  Back
                </v-btn>
              </v-stepper-content>              
            </v-stepper-items>
          </v-stepper>
        </template>
      </v-col>
    </v-row>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          {{ loadingMessage }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="tooManyRecords"
      persistent
      max-width="500"
    >
      <v-card class="pl-2 pr-2">
        <v-card-title class="headline">
          Large Number of Records
        </v-card-title>
        <v-row>
          <v-col>
            <v-alert
              type="success"
              icon="mdi-email"
            >
              Only a sample of record is being shown because there's too many records to retrieve at once. <br><br>
              <strong>Would you like the complete report to be emailed to you?</strong>
            </v-alert>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="emailReport"
          >
            Yes
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="tooManyRecords = false"
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>    
    <template v-if="items.length > 0">
      <v-row>
        <v-col>
          <h2>
            {{ formatNumber(items.length) }} Records
          </h2>
        </v-col>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-col class="text-right">
              <download-excel
                :data="parsedItems"
                type="csv"
                :name="fileName"
              >
                <v-icon
                  large
                  color="green darken-2"
                  v-on="on"
                >
                  mdi-microsoft-excel
                </v-icon>
              </download-excel>
            </v-col>
          </template>
          <span>Download</span>
        </v-tooltip>
      </v-row>
    </template>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
          </v-card-title>
          <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="selectedHeaders"
            :items="items"
            :search="search"
            :items-per-page="50"
            :sort-by="['make']"
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-card>
                  <v-card-title
                    class="headline"
                  >
                    More info about {{ item.year }}
                  </v-card-title>
                </v-card>
              </td>
            </template>
            <template #item.sales_price="{value}">
              {{ formatPrice(value) }}
            </template>
            <template #item.cost="{value}">
              {{ formatPrice(value) }}
            </template>
            <template #item.used="{value}">
              {{ formatNewUsed(value) }}
            </template>
            <template #item.sold_at="{value}">
              {{ formatDate(value) }}
            </template>
            <template #item.term_date="{value}">
              {{ formatDate(value) }}
            </template>
            <template #item.phone="{value}">
              {{ formatPhoneNumber(value) }}
            </template>
            <template #item.mileage="{value}">
              {{ formatNumber(value) }}
            </template>
            <template #item.term_complete_percent="{value}">
              <template>
                {{ formatPercent(value) }}
              </template>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import MailCampaignService from '@/services/MailCampaignService'
  import DmsService from '@/services/DmsService.js'
  import DmsReportingService from '@/services/DmsReportingService.js'
  import { mapGetters } from 'vuex'
  import { pick } from '@/mixins/exportMixin.js'
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import Vue from 'vue'
  
  export default {
    name: 'PurchasedNoService',
    components: {},
    mixins: [formatterMixin],
    data () {
      return {
        reportType: 'purchased_but_no_service_records',
        cacheKey: null,
        tooManyRecords: false,
        start_date: null,
        end_date: null,
        wizard: 1,
        start_date_menu: false,
        end_date_menu: false,
        use_all_dates_switch: true,
        use_all_makes_switch: true,
        filter_by_apr_switch: false,
        show_apr_dialog: false,
        show_payment_dialog: false,
        show_payments_remaining_dialog: false,
        show_months_since_sale_dialog: false,
        show_term_percent_dialog: false,
        filter_by_payment: false,
        filter_by_payments_remaining: false,
        filter_by_months_since_sale: false,
        filter_by_term_percent_complete: false,
        filter_by_makes_switch: false,
        filter_by_models_switch: false,
        filter_by_years_switch: false,
        filter_by_states_switch: true,
        filter_by_condition_switch: false,
        items: [],
        search: '',
        loading: false,
        loadingMessage: 'Loading',
        error: '',
        expanded: [],
        singleExpand: false,
        apr_filter_operator_value: 1,
        apr_filter_value: 10,
        payment_filter_operator_value: 1,
        payment_filter_value: 500,
        payments_remaining_filter_operator_value: 1,
        payments_remaining_filter_value: 12,
        months_since_sale_filter_operator_value: 1,
        months_since_sale_filter_value: 36,
        term_percent_filter_operator_value: 1,
        term_percent_filter_value: 80,
        all_makes: [],
        selected_makes: [],
        all_models: [],
        selected_models: [],
        all_years: [],
        selected_years: [],
        all_states: [],
        selected_states: ['TX'],
        all_conditions: ['New', 'Used'],
        selected_conditions: [],
        campaignId: null,
        fileDescription: 'Sales With No Service',
        campaigns: [],
        rules: {
          required: value => !!value || 'Required.',
        },
        filter_operators: [
          { text: 'Equals', icon: 'mdi-equal', operator: '=' },
          { text: 'Greater Than', icon: 'mdi-greater-than', operator: '>' },
          { text: 'Less Than', icon: 'mdi-less-than', operator: '<' },
        ],
        allHeaders: [
          { text: 'Customer Number', value: 'customer_number', align: 'center' },
          { text: 'Deal Number', value: 'deal_number' },
          { text: 'Sold Date', value: 'sold_at' },
          { text: 'Name', value: 'full_name', align: 'center' },
          { text: 'First Name', value: 'first_name', align: 'center' },
          { text: 'Last Name', value: 'last_name', align: 'center' },
          { text: 'City', value: 'city', align: 'center' },
          { text: 'Zip', value: 'zip', align: 'center' },
          { text: 'Year', value: 'year', align: 'center' },
          { text: 'Make', value: 'make', align: 'center' },
          { text: 'Model', value: 'model', align: 'center' },
          { text: 'Trim', value: 'trim', align: 'center' },
          { text: 'New/Used', value: 'used', align: 'center' },
          { text: 'Price', value: 'sales_price', align: 'center' },
          { text: 'Cost', value: 'cost', align: 'center' },
          { text: 'VIN', value: 'vin', align: 'center' },
          { text: 'Stock Number', value: 'stock_number', align: 'center' },
          { text: 'Mileage', value: 'mileage', align: 'center' },
          { text: 'Sales Person', value: 'sales_person', align: 'center' },
          { text: 'Address 1', value: 'address_1', align: 'center' },
          { text: 'Address 2', value: 'address_2', align: 'center' },
          { text: 'Phone', value: 'phone', align: 'center' },
          { text: 'Email', value: 'email', align: 'center' },
          { text: 'APR', value: 'apr', align: 'center' },
          { text: 'Term', value: 'bank_term', align: 'center' },
          { text: 'Monthly Payment', value: 'monthly_payment', align: 'center' },
          { text: 'Bank', value: 'bank_id', align: 'center' },
          { text: 'Down Payment', value: 'down_payment', align: 'center' },
          { text: 'Term Date', value: 'term_date', align: 'center' },
          { text: 'Payments Remaining', value: 'months_remaining', align: 'center' },
          { text: 'Term Complete %', value: 'term_complete_percent', align: 'center' },
          { text: 'Address Validated?', value: 'validated', align: 'center' },          
        ],
        selectedHeaders: [
          { text: 'First Name', value: 'first_name', align: 'center' },
          { text: 'Last Name', value: 'last_name', align: 'center' },
          { text: 'Address 1', value: 'address_1', align: 'center' },
          { text: 'Address 2', value: 'address_2', align: 'center' },
          { text: 'City', value: 'city', align: 'center' },
          { text: 'State', value: 'state', align: 'center' },
          { text: 'Zip', value: 'zip', align: 'center' },
          { text: 'Phone', value: 'phone', align: 'center' },
          { text: 'Email', value: 'email', align: 'center' },
          { text: 'Year', value: 'year', align: 'center' },
          { text: 'Make', value: 'make', align: 'center' },
          { text: 'Model', value: 'model', align: 'center' },
          { text: 'VIN', value: 'vin', align: 'center' },
          { text: 'Sold Date', value: 'sold_at' },
        ],
      }
    },
    computed: {
      formattedStartDate () {
        return this.formatDate(this.start_date)
      },
      formattedEndDate () {
        return this.formatDate(this.end_date)
      },
      parsedItems () {
        const headers = this.selectedHeaders.map(h => h.value)
        if (this.items) {
          return this.items.map(i => pick(i, headers))
        }
        return []
      },
      fileName () {
        return `${this.accountName
          .toLowerCase()
          .replace(' ', '_')}_purchased_no_service.xls`
      },
      ...mapGetters(['accountId', 'accountName', 'reportData', 'user']),
    },
    watch: {
      accountId () {
        this.loadSales()
        this.loadCampaigns()
      },
      reportData () {
        this.loadingMessage = "Rendering Report"
        if (this.reportData.reportType === this.reportType) {
          this.cacheKey = this.reportData.cacheKey
          this.items = []
          this.loading = true
          DmsReportingService.retrieveReport(this.reportData.cacheKey, this.reportData.campaignId, 'Vehicle sales with no service records', this.selectedHeaders.map(h => h.value), this.reportType, this.reportData.fileName)
            .then(response => {
              if (response.data.report.items) {
                this.items = response.data.report.items
                this.tooManyRecords = response.data.too_many_records
              } else {
                this.items = []
              }
              this.loading = false
            })
            .catch(error => {
              this.loading = false
              if (!error.response) {
                this.error = 'Network Error. Check your internet connection.'
              } else {
                var errorMessage = error.response.data.error
                if (errorMessage) {
                  this.error = errorMessage
                } else { this.error = 'The server returned an error. Please contact Dealer Insights support.' }
              }
            })
        }
      },
      use_all_dates_switch () {
        if (this.use_all_dates_switch) {
          this.start_date = null
          this.end_date = null
        } else {
          this.start_date = this.defaultStartDate()
          this.end_date = this.defaultEndDate()
        }
      },
      filter_by_apr_switch () {
        this.show_apr_dialog = this.filter_by_apr_switch
      },
      filter_by_payment () {
        this.show_payment_dialog = this.filter_by_payment
      },
      filter_by_payments_remaining () {
        this.show_payments_remaining_dialog = this.filter_by_payments_remaining
      },
      filter_by_months_since_sale () {
        this.show_months_since_sale_dialog = this.filter_by_months_since_sale
      },
      filter_by_term_percent_complete () {
        this.show_term_percent_dialog = this.filter_by_term_percent_complete
      },
    },
    created () {
      this.getMakes()
      this.getModels()
      this.getYears()
      this.getStates()
      this.loadCampaigns()
    },
    methods: {
      loadSales: function () {
        if (this.wizard !== 5) {
          return
        }
        Vue.set(this.user, 'report_data', {})
        this.items = []
        this.loadingMessage = "Contacting Server"
        this.loading = true
        var data = {
          startDate: this.start_date,
          endDate: this.end_date,
        }
        // Build up the params to call getSales with.
        if (this.filter_by_apr_switch) {
          data.aprOperator = this.filter_operators[this.apr_filter_operator_value].operator
          data.apr = this.apr_filter_value
        }

        if (this.filter_by_payment) {
          data.paymentOperator = this.filter_operators[this.payment_filter_operator_value].operator
          data.payment = this.payment_filter_value
        }

        if (this.filter_by_payments_remaining) {
          data.paymentsRemainingOperator = this.filter_operators[this.payments_remaining_filter_operator_value].operator
          data.paymentsRemaining = this.payments_remaining_filter_value
        }

        if (this.filter_by_months_since_sale) {
          data.monthsSinceSaleOperator = this.filter_operators[this.months_since_sale_filter_operator_value].operator
          data.monthsSinceSale = this.months_since_sale_filter_value
        }

        if (this.filter_by_term_percent_complete) {
          data.termCompletePercentOperator = this.filter_operators[this.term_percent_filter_operator_value].operator
          data.termCompletePercent = this.term_percent_filter_value
        }

        if (this.filter_by_makes_switch) {
          data.makes = this.selected_makes.join(',')
        }

        if (this.filter_by_models_switch) {
          data.models = this.selected_models.join(',')
        }

        if (this.filter_by_years_switch) {
          data.years = this.selected_years.join(',')
        }

        if (this.filter_by_states_switch) {
          data.states = this.selected_states.join(',')
        }

        if (this.filter_by_condition_switch) {
          data.conditions = this.selected_conditions.join(',')
        }

        data.headerText = this.selectedHeaders.map(h => encodeURIComponent(h.text))
        data.headerValues = this.selectedHeaders.map(h => encodeURIComponent(h.value))
        data.campaignId = this.campaignId 
        data.fileDescription = this.fileDescription
        DmsReportingService.purchasedButNoServiceRecords(data)
          .then(response => {
            this.loadingMessage = "Running Report"
            if (response.data.items) {
              this.items = response.data.items
            } else {
              this.items = []
            }
          })
          .catch(error => {
            this.loading = false
            if (!error.response) {
              this.error = 'Network Error. Check your internet connection.'
            } else {
              var errorMessage = error.response.data.error
              if (errorMessage) {
                this.error = errorMessage
              } else { this.error = 'The server returned an error. Please contact Dealer Insights support.' }
            }
          })
      },
      emailReport: function () {
        this.loading = true
        var data = {
          cacheKey: this.reportData.fileName,
          headerValues: this.selectedHeaders.map(h => encodeURIComponent(h.value)),
        }

        DmsReportingService.emailReport(data)
          .then(response => {
            this.tooManyRecords = false
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.tooManyRecords = false
            if (!error.response) {
              this.error = 'Network Error. Check your internet connection.'
            } else {
              var errorMessage = error.response.data.error
              if (errorMessage) {
                this.error = errorMessage
              } else { this.error = 'The server returned an error. Please contact Dealer Insights support.' }
            }
          })
      },
      loadCampaigns: function () {
        this.campaignId = null
        this.campaigns = []
        this.loading = true
        MailCampaignService.getMailCampaigns()
          .then(response => {
            this.campaigns = response.data
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            if (!error.response) {
              this.error = 'Network Error. Check your internet connection.'
            } else {
              var errorMessage = error.response.data.error
              this.error = errorMessage
            }
          })
      },
      getMakes: function () {
        this.loading = true
        DmsService.getSalesMakes()
          .then(response => {
            if (response.data.makes) {
              this.all_makes = response.data.makes
            } else {
              this.all_makes = []
            }
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            if (!error.response) {
              this.error = 'Network Error. Check your internet connection.'
            } else {
              var errorMessage = error.response.data.error
              if (errorMessage) {
                this.error = errorMessage
              } else { this.error = 'The server returned an error. Please contact Dealer Insights support.' }
            }
          })
      },
      getModels: function () {
        this.loading = true
        DmsService.getSalesModels()
          .then(response => {
            if (response.data.models) {
              this.all_models = response.data.models
            } else {
              this.all_models = []
            }
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            if (!error.response) {
              this.error = 'Network Error. Check your internet connection.'
            } else {
              var errorMessage = error.response.data.error
              if (errorMessage) {
                this.error = errorMessage
              } else { this.error = 'The server returned an error. Please contact Dealer Insights support.' }
            }
          })
      },
      getYears: function () {
        this.loading = true
        DmsService.getSalesYears()
          .then(response => {
            if (response.data.years) {
              this.all_years = response.data.years
            } else {
              this.all_years = []
            }
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            if (!error.response) {
              this.error = 'Network Error. Check your internet connection.'
            } else {
              var errorMessage = error.response.data.error
              if (errorMessage) {
                this.error = errorMessage
              } else { this.error = 'The server returned an error. Please contact Dealer Insights support.' }
            }
          })
      },
      getStates: function () {
        this.loading = true
        DmsService.getSalesStates()
          .then(response => {
            if (response.data.states) {
              this.all_states = response.data.states
            } else {
              this.all_states = []
            }
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            if (!error.response) {
              this.error = 'Network Error. Check your internet connection.'
            } else {
              var errorMessage = error.response.data.error
              if (errorMessage) {
                this.error = errorMessage
              } else { this.error = 'The server returned an error. Please contact Dealer Insights support.' }
            }
          })
      },
      defaultStartDate () {
        return new Date(new Date().setDate(new Date().getDate() - 360)).toISOString().substring(0, 10)
      },
      defaultEndDate () {
        return new Date().toISOString().substring(0, 10)
      },
      formatFilterMessage (switchName, switchObject, filterOperator, filterValue) {
        if (switchObject) {
          const message = `${this.filter_operators[filterOperator].text} ${filterValue}`
          return `Filter by ${switchName}: ${switchObject ? message : 'No'}`
        } else {
          return `Filter by ${switchName}: No`
        }
      },
    },
  }
</script>
